import i18next from "i18next";
import { cloneDeep } from "lodash";
import type { NodeType } from "polotno/model/node-model";

import { EEventType, EVariantTranslation } from "../types/enums";

/**
 *
 * @param type Type of item (Certificate/badge)
 * @param variant The text case
 * @returns translation of `type` according to `variant`
 */
export const getTranslatedCertificateOrBadge = (type: EEventType | undefined, variant: EVariantTranslation) => {
    if (type === "certificate") {
        switch (variant) {
            case "lowercase_singular":
                return i18next.t("common.certificate");
            case "lowercase_plural":
                return i18next.t("common.certificates");
            case "uppercase_singular":
                return i18next.t("common.Certificate");
            case "uppercase_plural":
                return i18next.t("common.Certificates");
            default:
                return i18next.t("common.certificate");
        }
    }
    if (type === "badge") {
        switch (variant) {
            case "lowercase_singular":
                return i18next.t("common.badge");
            case "lowercase_plural":
                return i18next.t("common.badges");
            case "uppercase_singular":
                return i18next.t("common.Badge");
            case "uppercase_plural":
                return i18next.t("common.Badges");
            default:
                return i18next.t("common.badge");
        }
    }
    return "";
};

export const findRecipientImageInBadgeProps = (badgeProps: VbDesigner.IBadgeProps) => {
    return badgeProps.userInformationObjects.find((obj) => obj.id === "user-profile-pic");
};

/**
 * Checks if an element is within the canvas boundaries.
 *
 * Some old templates have profile picture elements that are outside the canvas boundaries.
 * In this case they should be considered as not visible.
 *
 * @param element - The element to check.
 * @returns A boolean indicating whether the element is on the canvas.
 */
const isElementOnCanvas = (element: NodeType, documentWidth: number, documentHeight: number) => {
    const { x, y, width, height, visible } = element;
    if (!visible) return false;
    return x + width >= 0 && y + height >= 0 && x <= documentWidth && y <= documentHeight;
};

export const findRecipientImageInPolotnoProps = (
    polotnoProps: PolotnoDesigner.PolotnoBadgePropsData
): PolotnoDesigner.PolotnoObject | undefined => {
    let polotnoElement;
    polotnoProps.pages.forEach((page) => {
        page.children.forEach((element) => {
            if (
                element.custom &&
                element.custom.type === "recipient_image" &&
                isElementOnCanvas(element, polotnoProps?.width ?? 0, polotnoProps?.height ?? 0)
            ) {
                polotnoElement = element;
            }
        });
    });
    return polotnoElement;
};

/**
 * Make profile pic invisible in polotno props if it has svg type that means it is a dummy profile pic
 * @param polotnoProps Polotno props
 * @returns Polotno props with profile pic invisible
 */
export const disableProfilePicInPolotnoProps = (
    polotnoProps: PolotnoDesigner.PolotnoBadgePropsData
): PolotnoDesigner.PolotnoBadgePropsData => {
    const clonedPolotnoProps = cloneDeep(polotnoProps);
    const profilePic = findRecipientImageInPolotnoProps(clonedPolotnoProps);
    if (profilePic) {
        profilePic.visible = false;
    }
    return clonedPolotnoProps;
};

/**
 * Make profile pic invisible in badge props if it has svg type that means it is a dummy profile pic
 * @param badgeProps Badge props
 * @returns boolean
 */
export const checkProfilePicture = (profilePic: VbDesigner.BadgeObject | undefined, bypassProfilePicture: boolean) => {
    const isDummyProfilePic = profilePic?.img?.src ? profilePic.img.src.includes("user_profile_dummy") : false;

    return (!bypassProfilePicture && !profilePic?.disabled && isDummyProfilePic) ?? false;
};

/**
 * Make profile pic invisible in polotno props if it has svg type that means it is a dummy profile pic
 * @param polotnoProps Polotno props
 * @returns boolean
 */
export const checkPolotnoProfilePicture = (
    profilePic: PolotnoDesigner.PolotnoObject | undefined,
    bypassProfilePicture: boolean
) => {
    const isDummyProfilePic = profilePic?.src?.includes("user_profile_dummy");
    const emptyProfilePic =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AAAAAAAAFAAFkeJU4AAAAAElFTkSuQmCC";

    return (
        (!bypassProfilePicture &&
            profilePic?.visible &&
            (profilePic?.type === "svg" || isDummyProfilePic || profilePic?.src === emptyProfilePic)) ??
        false
    );
};

/**
 * Helper function to build a URL with query parameters
 * @param base
 * @param queryParams
 * @returns {string}
 */
export const buildUrl = (base: string, queryParams: { [key: string]: string | undefined | null }) => {
    const url = new URL(base);

    Object.keys(queryParams).forEach((key) => {
        const value = queryParams[key];
        if (value) {
            url.searchParams.append(key, value);
        }
    });

    return url.toString();
};

/** Downloads a file from the given URL.
 * @param url The URL of the file to be downloaded.
 * @param filename Optional. The name to give to the downloaded file. If not provided, the file name from the URL will be used.
 */
export const downloadFile = async (url: string, filename?: string) => {
    try {
        const downloadLink = document.createElement("a");
        const fileBlob = await fetch(url).then((r) => r.blob());
        downloadLink.href = URL.createObjectURL(fileBlob);
        downloadLink.download = filename || url.split("/").pop() || "downloaded_file";
        document.body.appendChild(downloadLink);
        downloadLink.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true, view: window }));
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error downloading the file:", error);
    }
};
