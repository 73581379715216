import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import UploadProfilePicture from "features/certificateDownload/components/UploadProfilePicture/UploadProfilePicture";

import usePushSnack from "hooks/SnackbarManager/usePushSnack";
import useTranslation from "i18n/hooks/useTranslation";
import { routes } from "utils/routes";

import { Stack } from "@mui/material";

import Spinner from "../../../../components/Spinner";
import { useGetCurrentSelectedCertificate } from "../../../../redux/hooks/badge";
import useLoadingThunk from "../../../../redux/hooks/loading";
import certificatesSelectorService from "../../../../redux/selector/certificates";
import eventSelectorService from "../../../../redux/selector/events";
import { useAppSelector } from "../../../../redux/store";
import { EBadgeFormat } from "../../../../types/enums";
import { AnyObject } from "../../../../types/global";
import backendPdfDownloadHelper from "../../../../utils/backendPdfDownloadHelper";
import isOnBadgeRoute from "../../../../utils/isOnBadgeRoute";
import { LIGHTER_BLUE } from "../../../../utils/Theme/theme";
import { urlToBase64 } from "../../../../utils/urlToBase64";

import GetCertificatePreview from "./GetCertificatePreview";

const getWidth = (certificateType?: EBadgeFormat) => {
    switch (certificateType) {
        case EBadgeFormat.US_LETTER_PORTRAIT:
        case EBadgeFormat.A4_PORTRAIT:
        case EBadgeFormat.BADGE_1_TO_1:
            return { maxWidth: { md: 500 }, minWidth: { sm: 500 } };
        case EBadgeFormat.US_LETTER_LANDSCAPE:
        case EBadgeFormat.A4_LANDSCAPE:
        default:
            return { maxWidth: { md: 650 }, minWidth: { sm: 500 } };
    }
};

const CertificateValidatorPreview: FC = () => {
    const t = useTranslation();
    const pushSnack = usePushSnack();
    const navigate = useNavigate();
    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);
    const certificates = useAppSelector(certificatesSelectorService.selectCertificates);

    const currentCertificate = useGetCurrentSelectedCertificate();
    const [loadedPreview, setLoadedPreview] = useState("");
    const [eventPreview, setEventPreview] = useState<undefined | string>(undefined);
    const [reloadBadgeImage, setReloadBadgeImage] = useState<boolean>(false);
    const { loadingStates } = useLoadingThunk();

    const selectedCertificatePreview =
        currentCertificate?.badge_image_url || currentCertificate?.badge_base_string || eventPreview;

    const isBadgeRoute = isOnBadgeRoute();

    useEffect(() => {
        const response = eventDetails as unknown as AxiosError<AnyObject>;
        // If certificate not found redirect to landing
        if (
            response?.response?.status === 404 ||
            (response?.response?.status === 400 && response?.response?.data?.message === "paused")
        ) {
            pushSnack({
                title: t("common.alerts.messages.certificate_not_found_title"),
                body: t("common.alerts.messages.certificate_not_found_message"),
                type: "error",
            });
            navigate(routes.LandingPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventDetails]);

    const replacePreview = async (url: string) => {
        const previewBase64 = await urlToBase64(url);
        return previewBase64;
    };

    useEffect(() => {
        const changeBakedPreview = async () => {
            setReloadBadgeImage(true);
            // Change preview after badge is baked
            if (!certificates.reloadBadgeImage && selectedCertificatePreview && isBadgeRoute) {
                const parsedImage = await replacePreview(selectedCertificatePreview);
                setLoadedPreview(parsedImage);
                setReloadBadgeImage(false);
            }
        };
        changeBakedPreview();
    }, [selectedCertificatePreview, certificates.reloadBadgeImage, isBadgeRoute]);

    useEffect(() => {
        const changeEventPreview = async () => {
            if (eventPreview === undefined && eventDetails?.event_badge_preview_url) {
                setEventPreview(eventDetails.event_badge_preview_url);
            }
        };
        changeEventPreview();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventDetails, eventPreview]);

    const checkSingleRecipient = backendPdfDownloadHelper.checkSingleRecipient(
        eventDetails.event_pdf_type,
        currentCertificate
    );

    const disablePreview =
        (checkSingleRecipient.isBackendPdf && !checkSingleRecipient.isGenerated) ||
        loadingStates.loading.backendGeneration;

    return (
        <Stack
            pr={{ lg: 10, xs: 5 }}
            pl={{ lg: 0, xs: 5 }}
            {...getWidth(eventDetails?.badge_format)}
            justifyContent="space-around"
            flex="1"
        >
            <div style={{ position: "relative" }}>
                {eventDetails && (
                    <GetCertificatePreview
                        certificateType={eventDetails?.badge_format}
                        image={!isBadgeRoute || reloadBadgeImage ? eventPreview || "" : loadedPreview}
                        disabled={isBadgeRoute && disablePreview}
                    />
                )}
                {((isBadgeRoute && (certificates.reloadBadgeImage || reloadBadgeImage)) ||
                    (!isBadgeRoute && !eventPreview) ||
                    loadingStates.loading.backendGeneration) && (
                    <div
                        style={{
                            backdropFilter: "blur(4px)",
                            position: "absolute",
                            display: "flex",
                            width: "96%",
                            height: "95%",
                            justifyContent: "center",
                            alignItems: "center",
                            top: "12px",
                            left: "12px",
                            right: "0px",
                            bottom: "0px",
                            zIndex: "2",
                            backgroundColor: `${LIGHTER_BLUE}55`,
                            border:
                                eventDetails.badge_format === EBadgeFormat.BADGE_1_TO_1 ? `1px solid #e9e9e9` : "none",
                            borderRadius: eventDetails.badge_format === EBadgeFormat.BADGE_1_TO_1 ? "8px" : "0px",
                        }}
                    >
                        <Spinner />
                    </div>
                )}
            </div>
            {eventDetails && isBadgeRoute && (
                <UploadProfilePicture
                    disableUpload={certificates.reloadBadgeImage}
                    onUploadImage={() => {
                        setLoadedPreview(eventPreview || "");
                        setReloadBadgeImage(true);
                    }}
                />
            )}
        </Stack>
    );
};

export default CertificateValidatorPreview;
