import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { findRecipientImageInPolotnoProps } from "utils/misc";

import { selectStore } from "../store";

import controlSelectorService from "./control";

const selectCertificates = createDraftSafeSelector(selectStore, (store) => store.certificates);

const selectedCertificate = createDraftSafeSelector(
    [selectCertificates, controlSelectorService.selectUserFlow],
    (certificates, userFlow) => certificates.data[userFlow.showedCertificate] || undefined
);

const selectedCertificateHasProfilePictureAttached = createDraftSafeSelector(
    selectedCertificate,
    (selected) => selected.profile_picture_attached
);

const isPolotnoProps = createDraftSafeSelector(
    selectCertificates,
    (certificates) => certificates.data.find((cert) => cert.badge_props_type === "polotno") !== undefined
);

const isSingleCertificate = createDraftSafeSelector(
    selectCertificates,
    (certificates) => certificates.data.length === 1
);

const isMultipleCertificates = createDraftSafeSelector(
    selectCertificates,
    (certificates) => certificates.data.length > 1
);

const selectPolotnoBadgeProps = createDraftSafeSelector(
    selectCertificates,
    (certificates) => certificates.data.find((cert) => cert.badge_props_type === "polotno")?.badge_props
);

const selectRichTextModeEnabled = createDraftSafeSelector(
    [selectPolotnoBadgeProps],
    (polotnoBadgeProps) => (polotnoBadgeProps as PolotnoDesigner.PolotnoBadgePropsData)?.isRichTextEnabled
);

const hasPolotnoPropsProfilePictureEnabled = createDraftSafeSelector([selectPolotnoBadgeProps], (polotnoBadgeProps) => {
    findRecipientImageInPolotnoProps(polotnoBadgeProps as PolotnoDesigner.PolotnoBadgePropsData);
});

const certificatesSelectorService = {
    selectCertificates,
    isSingleCertificate,
    isMultipleCertificates,
    selectedCertificate,
    isPolotnoProps,
    selectedCertificateHasProfilePictureAttached,
    selectRichTextModeEnabled,
    hasPolotnoPropsProfilePictureEnabled,
};

export default certificatesSelectorService;
