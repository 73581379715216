import { useDebugValue, useMemo } from "react";

/**
 * Parse to the navigator.userAgent to get information of user device.
 * @param {NavigatorID["userAgent"]} ua navigator.userAgent to get information of user device.
 * @returns {string} User deviec as string.
 */
const getDeviceName = (ua: NavigatorID["userAgent"]): string => {
    let deviceName = "";

    const isMobile = {
        Android() {
            return ua.match(/Android/i);
        },
        Datalogic() {
            return ua.match(/DL-AXIS/i);
        },
        Bluebird() {
            return ua.match(/EF500/i);
        },
        Honeywell() {
            return ua.match(/CT50/i);
        },
        Zebra() {
            return ua.match(/TC70|TC55/i);
        },
        BlackBerry() {
            return ua.match(/BlackBerry/i);
        },
        iOS() {
            return ua.match(/iPhone|iPad|iPod/i);
        },
        Windows() {
            return ua.match(/IEMobile/i);
        },
        any() {
            return (
                isMobile.Datalogic() ||
                isMobile.Bluebird() ||
                isMobile.Honeywell() ||
                isMobile.Zebra() ||
                isMobile.BlackBerry() ||
                isMobile.Android() ||
                isMobile.iOS() ||
                isMobile.Windows()
            );
        },
    };

    if (isMobile.Datalogic()) deviceName = "Datalogic";
    else if (isMobile.Bluebird()) deviceName = "Bluebird";
    else if (isMobile.Honeywell()) deviceName = "Honeywell";
    else if (isMobile.Zebra()) deviceName = "Zebra";
    else if (isMobile.BlackBerry()) deviceName = "BlackBerry";
    else if (isMobile.iOS()) deviceName = "iOS";
    else if (deviceName == "" && isMobile.Android()) deviceName = "Android";
    else if (deviceName == "" && isMobile.Windows()) deviceName = "Windows";

    if (deviceName != "") {
        console.log(`Devices information deviceName = ${deviceName}`);
        console.log(`Devices information any = ${isMobile.any()}`);
        console.log(`navigator.userAgent = ${ua}`);
    }

    return deviceName;
};

/**
 * Checks the possibility to attach to defined wallet
 * @param {NavigatorID["userAgent"]} ua navigator.userAgent to get information of user device.
 * @param {"andorid" | "ios"} check Define which check should be done.
 * @returns {boolean} Is the user device avaible to attach the wallet button.
 */
const walletAvaible = (ua: NavigatorID["userAgent"], check: "andorid" | "ios"): boolean => {
    switch (check) {
        case "andorid":
            return /Android/i.test(ua);
        case "ios":
            // From guideline the apple wallet button needs to be used only on iPhone and iPod devices.
            return /iPhone|iPod/i.test(ua);
        default:
            return false;
    }
};

/**
 * Hook to get information of user agent with to specific values and functions.
 * @returns {{device: string, walletAvaibleCheck: {google: boolean, apple: boolean}}} Information of user device and check if the user device is avaible to attach the wallet button.
 */
const useGetUserAgent = () => {
    // Memorized navigator.userAgent
    const memorizedUserAgent = useMemo<NavigatorID["userAgent"]>(() => navigator.userAgent, []);

    /**
     * String of user device.
     *
     * Can be: "Android", "iOS", "Windows", "BlackBerry", "Datalogic", "Bluebird", "Honeywell", "Zebra".
     *
     * Can be empty string if the user device is not avaible.
     */
    const device = useMemo(() => getDeviceName(memorizedUserAgent), [memorizedUserAgent]);

    /**
     * Object with the check if the user device is available to attach the wallet button.
     * Returns google for android device check.
     * Returns apple for ios (iPhone and iPod) device check.
     */
    const walletAvaibleCheck = useMemo(
        () => ({
            google: walletAvaible(memorizedUserAgent, "andorid"),
            apple: walletAvaible(memorizedUserAgent, "ios"),
        }),
        [memorizedUserAgent]
    );

    const values = useMemo(() => ({ device, walletAvaibleCheck }), [device, walletAvaibleCheck]);

    useDebugValue(values);

    return values;
};

export default useGetUserAgent;
