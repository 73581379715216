import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { ESeverity } from "../../types/enums";
import isOnBadgeRoute from "../../utils/isOnBadgeRoute";
import badgeActionService from "../actions/badge";
import certificatesActionService from "../actions/certificates";
import sideActionsService from "../actions/sideActions";
import validationActionService from "../actions/validation";

import type { TControlState } from "./types/control.types";

const initialState: TControlState = {
    slugs: {},
    tokenValidation: {
        severity: ESeverity.ERROR,
        message: "",
        loadingState: "unfetched",
    },
    token: isOnBadgeRoute() ? localStorage.getItem("token") || "" : "",
    userEmail: "",
    socialMediaProfiles: {},
    userFlow: {
        showedCertificate: 0,
        showPreview: true,
        showCertificateOverview: false,
        verificationMethod: null,
        otpTokenSent: false,
        newCertificatesFound: [],
        newCertificateLoadingState: {
            certificatesInQueue: 0,
            certificatesTotal: 0,
            certificatesFinished: 0,
            loadingState: null,
            loopStart: 0,
            loopEnd: 0,
        },
    },
    download: {
        certificatesToDownload: [],
        amountOfDownloadingCertificates: 0,
        downloadFormat: undefined,
        downloadProgressBar: {
            total: 0,
            current: 0,
        },
    },
    badgeIsParsing: false,
    loading: {
        download: false,
        propsMapped: false,
        badgesBaked: false,
        /**
         * "validateOtpToken" should only used inside AuthForm.tsx or the integrated helpers, hooks.
         * This state will not change to false after the otp token was validated successfully.
         * It only change back to false if the otp-token is invalid/expired.
         */
        validateOtpToken: false,
        backendGeneration: false,
    },
    shareToSocialMedia: {
        twitter: {
            successfull: false,
            error: false,
            pending: false,
        },
        facebook: {
            successfull: false,
            error: false,
            pending: false,
        },
        linkedin: {
            successfull: false,
            error: false,
            pending: false,
        },
        instagram: {
            successfull: false,
            error: false,
            pending: false,
        },
        whatsapp: {
            successfull: false,
            error: false,
            pending: false,
        },
    },
};

const controlSlice = createSlice({
    name: "control",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(sideActionsService.resetDownloadProgress, (state) => ({
                ...state,
                download: { ...state.download, downloadProgressBar: initialState.download.downloadProgressBar },
            }))
            .addCase(sideActionsService.setDownloadProgressTotal, (state, action) => ({
                ...state,
                download: {
                    ...state.download,
                    downloadProgressBar: { ...state.download.downloadProgressBar, total: action.payload },
                },
            }))
            .addCase(sideActionsService.setDownloadProgress, (state, action) => ({
                ...state,
                download: {
                    ...state.download,
                    downloadProgressBar: { ...state.download.downloadProgressBar, current: action.payload },
                },
            }))
            .addCase(sideActionsService.setNewCertificateLoadingLoopTime, (state, action) => ({
                ...state,
                userFlow: {
                    ...state.userFlow,
                    newCertificateLoadingState: {
                        ...state.userFlow.newCertificateLoadingState,
                        ...(action.payload.type === "start" && {
                            loopStart:
                                state.userFlow.newCertificateLoadingState.loopStart === 0
                                    ? action.payload.ms
                                    : state.userFlow.newCertificateLoadingState.loopStart,
                        }),
                        ...(action.payload.type === "end" && {
                            loopEnd:
                                state.userFlow.newCertificateLoadingState.loopEnd === 0
                                    ? action.payload.ms
                                    : state.userFlow.newCertificateLoadingState.loopEnd,
                        }),
                    },
                },
            }))
            .addCase(sideActionsService.setFoundNewCertificate, (state, action) => {
                const foundedNewCertificates = state.userFlow.newCertificatesFound.slice() as Data[];
                foundedNewCertificates.push(action.payload);
                return {
                    ...state,
                    userFlow: {
                        ...state.userFlow,
                        newCertificatesFound: foundedNewCertificates,
                        newCertificateLoadingState: {
                            ...state.userFlow.newCertificateLoadingState,
                            certificatesTotal: foundedNewCertificates.length,
                        },
                    },
                };
            })
            .addCase(sideActionsService.setNewCertificatesInQueue, (state, action) => {
                return {
                    ...state,
                    userFlow: {
                        ...state.userFlow,
                        newCertificateLoadingState: {
                            ...state.userFlow.newCertificateLoadingState,
                            certificatesInQueue: action.payload,
                        },
                    },
                };
            })
            .addCase(sideActionsService.setNewCertificatesFinished, (state, action) => {
                return {
                    ...state,
                    userFlow: {
                        ...state.userFlow,
                        newCertificateLoadingState: {
                            ...state.userFlow.newCertificateLoadingState,
                            certificatesFinished:
                                state.userFlow.newCertificateLoadingState.certificatesFinished + action.payload,
                        },
                    },
                };
            })
            .addCase(sideActionsService.setNewCertificateLoadingState, (state, action) => {
                return {
                    ...state,
                    userFlow: {
                        ...state.userFlow,
                        newCertificateLoadingState: {
                            ...state.userFlow.newCertificateLoadingState,
                            loadingState: action.payload,
                            ...(action.payload === null && {
                                loopStart: 0,
                                loopEnd: 0,
                            }),
                        },
                    },
                };
            })
            .addCase(sideActionsService.removeFoundNewCertificates, (state, action) => {
                const restNewCertificates = state.userFlow.newCertificatesFound.filter(
                    (certificate) => !action.payload.includes(certificate.certificate_id)
                );
                return {
                    ...state,
                    userFlow: {
                        ...state.userFlow,
                        newCertificatesFound: restNewCertificates,
                    },
                };
            })
            .addCase(badgeActionService.setBadgesAreBakedLoadingState, (state, action) => ({
                ...state,
                loading: {
                    ...state.loading,
                    badgesBaked: action.payload === false ? false : state.userFlow.newCertificatesFound.length === 0,
                },
            }))
            .addCase(sideActionsService.shareToSocialMedia, (state, action) => ({
                ...state,
                shareToSocialMedia: {
                    ...state.shareToSocialMedia,
                    [action.payload.platform]: {
                        ...initialState.shareToSocialMedia[action.payload.platform],
                        [action.payload.type]: true,
                    },
                },
            }))
            .addCase(sideActionsService.fromSharedRedirection.fulfilled, (state, action) => {
                return {
                    ...state,
                    userFlow: {
                        ...state.userFlow,
                        showedCertificate: action.payload,
                        showCertificateOverview: false,
                    },
                };
            })
            .addCase(validationActionService.setOrgaSlugs, (state, action) => ({ ...state, slugs: action.payload }))
            .addCase(validationActionService.validateToken.fulfilled, (state, action) => {
                let showedCertificate = 0;
                let lastShowedCertificate = localStorage.getItem("last_viewed_certificate");
                if (action.payload.certificates.length > 1) {
                    const certificateIndex = lastShowedCertificate
                        ? action.payload.certificates.findIndex(
                              (certificate) => certificate.certificate_id === lastShowedCertificate
                          )
                        : 0;
                    showedCertificate = certificateIndex > 0 ? certificateIndex : 0;
                } else {
                    localStorage.removeItem("last_viewed_certificate");
                    lastShowedCertificate = null;
                }
                return {
                    ...state,
                    tokenValidation: {
                        severity: action.payload.access.severity,
                        message: action.payload.access.message,
                        loadingState: "fulfilled",
                    },
                    userEmail: action.payload.access.userEmail,
                    userFlow: {
                        ...state.userFlow,
                        showedCertificate,
                        showCertificateOverview: lastShowedCertificate ? true : action.payload.certificates.length > 1,
                    },
                };
            })
            .addCase(validationActionService.validateToken.pending, (state) => ({
                ...state,
                tokenValidation: { ...state.tokenValidation, loadingState: "pending" },
            }))
            .addCase(validationActionService.validateToken.rejected, (state) => {
                // Remove the token from the local storage
                localStorage.removeItem("token");
                localStorage.removeItem("last_viewed_certificate");
                return {
                    ...state,
                    tokenValidation: { ...initialState.tokenValidation, loadingState: "rejected" },
                    token: "",
                    userEmail: "",
                    userFlow: {
                        ...state.userFlow,
                        showCertificateOverview: false,
                    },
                };
            })
            .addCase(validationActionService.setToken, (state, action) => {
                // Replace the token in the local storage
                localStorage.setItem("token", action.payload);
                // Set the token in the redux store
                return {
                    ...state,
                    token: action.payload,
                };
            })
            .addCase(badgeActionService.attachFieldMappingToBadge.pending, (state) => ({
                ...state,
                loading: {
                    ...state.loading,
                    propsMapped: false,
                },
            }))
            .addCase(badgeActionService.attachFieldMappingToBadge.fulfilled, (state) => ({
                ...state,
                userFlow: {
                    ...state.userFlow,
                    showPreview: false,
                },
                loading: {
                    ...state.loading,
                    propsMapped: state.userFlow.newCertificatesFound.length === 0,
                },
            }))
            .addCase(certificatesActionService.selectCertificate, (state, action) => ({
                ...state,
                userFlow: {
                    ...state.userFlow,
                    showedCertificate: action.payload,
                    showCertificateOverview: false,
                    showPreview: false,
                },
            }))
            .addCase(certificatesActionService.setDownloadFormat, (state, action) => ({
                ...state,
                download: {
                    ...state.download,
                    downloadFormat: action.payload,
                },
            }))
            .addCase(certificatesActionService.selectCertificateToDownload, (state, action) => {
                const { certificate, replacementType } = action.payload;
                let newDownloadState = state.download;

                switch (replacementType) {
                    case "add":
                        newDownloadState = {
                            ...state.download,
                            certificatesToDownload: [...state.download.certificatesToDownload, certificate],
                            amountOfDownloadingCertificates: state.download.amountOfDownloadingCertificates + 1,
                        };
                        break;
                    case "remove":
                        newDownloadState = {
                            ...state.download,
                            certificatesToDownload: state.download.certificatesToDownload.filter(
                                (currCertificate) => currCertificate !== certificate
                            ),
                            amountOfDownloadingCertificates: state.download.amountOfDownloadingCertificates - 1,
                        };
                        break;
                    default:
                        break;
                }
                return { ...state, download: newDownloadState };
            })
            .addCase(certificatesActionService.removeAllCertificateToDownload, (state) => ({
                ...state,
                download: {
                    ...state.download,
                    amountOfDownloadingCertificates: 0,
                    certificatesToDownload: [],
                },
            }))
            .addCase(sideActionsService.goBackToDashboard, (state) => {
                localStorage.removeItem("last_viewed_certificate");
                return {
                    ...state,
                    userFlow: {
                        ...state.userFlow,
                        showCertificateOverview: true,
                    },
                };
            })
            .addCase(sideActionsService.setBadgeParseLoadingState, (state, action) => ({
                ...state,
                badgeIsParsing: action.payload,
            }))
            .addCase(sideActionsService.setLoadingState, (state, action) => ({
                ...state,
                loading: { ...state.loading, [action.payload.type]: action.payload.state },
            }))
            .addMatcher(
                isAnyOf(validationActionService.sendOtpToken.rejected, validationActionService.sendOtpToken.pending),
                (state) => ({
                    ...state,
                    userFlow: {
                        ...state.userFlow,
                        verificationMethod: null,
                        otpTokenSent: false,
                    },
                })
            )
            .addMatcher(
                isAnyOf(validationActionService.sendOtpToken.fulfilled, sideActionsService.setVerifyMethod),
                (state, action) => ({
                    ...state,
                    userFlow: {
                        ...state.userFlow,
                        verificationMethod: action.payload,
                        otpTokenSent: true,
                    },
                })
            );
    },
});

export default controlSlice.reducer;
